@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

* {
    font-family: Poppins, sans-serif;
}


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}


a.medisharp-logo {
    margin-top: 2px;
}

a.medisharp-logo-footer {
    opacity: 0.25;
}

a.medisharp-logo img {
    max-height: 50px;
}



.medisharp-button {
    text-decoration: none;
    color: #fff;
    background: #07796a;
    padding: 20px;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 16px;
}
.medisharp-button span {
    color: #fff;
}

.medisharp-white-button {
    text-decoration: none;
    color: #606972;
    background: #ffffff;
    display: inline-block;
    border-radius: 3px;
    border: 1px solid #ced4da;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
}

.medisharp-transparent-button {
    text-decoration: none;
    color: #fff;
    background: transparent;
    display: inline-block;
    border-radius: 3px;
    border: 1px solid #fff;
    font-weight: 300 !important;
    font-size: 14px;
    cursor: pointer;
}

.medisharp-transparent-button:hover {
    opacity: 0.7;
}

.medisharp-white-button:hover {
    color: #151515;
    border-color: #151515;
}

.medisharp-sm-button {
    padding: 5px 10px;
    font-weight: 400;
}

.medisharp-pulse-green {
    animation: pulse-green 2s infinite;
}

.medisharp-button:hover {
    background-color: #038f7a;
}

.navlink-footer {
    -webkit-tap-highlight-color: transparent;
    color: rgb(134, 142, 150);
    font-size: 14px;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0px;
    border: 0px;
    line-height: 1;
}

.p-top-40 {
    padding-top: 40px;
}

body.appointment-layout {
    background-color: #edf2fd;
}

.mh-100 {
    min-height: calc(100vh - 296px);
}

.medisharp-search-wrapper input {
    min-height: 50px;
    padding-left: 60px;
}

.medisharp-specialty-wrapper, .medisharp-date-wrapper  {
    position: relative;
}

.medisharp-input-wrapper .mantine-Select-icon, .medisharp-input-wrapper .mantine-DatePicker-icon {
    width: 50px;
    border-right: 1px solid #ced4da;
    top: 1px !important;
    bottom: 1px !important;
}

.medisharp-specialty-icon {
    color: #038f7a;
    width: 24px;
    height: 24px;
}

.medisharp-date-icon {
    color: #038f7a;
    width: 20px;
    height: 24px;
}

.medisharp-input-wrapper label {
    margin-bottom: 10px;
}

.medisharp-doctor-wrapper {
    border: 1px solid rgb(196 207 227 / 70%);
    margin: 15px 0 0 0;
    border-radius: 5px;
}

.medisharp-slots-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    align-content: center;
    gap: 5px;
    background-color: #fbfcff;
    border-left: 1px solid rgb(196 207 227 / 70%);
}

.medisharp-doctor-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-form-wrapper label {
    margin-bottom: 5px;
}

.card-form-wrapper input {
    min-height: 50px;
}

.mh-50 input{
    min-height: 50px;
}

button.medisharp-button.medisharp-submit-button {
    padding: 16px 20px;
}

.doctor-avatar {
    background-color: #fccd00;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    user-select: none;
}

.medisharp-confirm-appointment button {
    width: 100%;
}

.confirm-page-title {
    text-align: center;
    color: #151515;
    font-weight: 600;
    font-size: 28px;
}

.medisharp-mobile-icon {
    font-size: 60px;
    display: block;
    text-align: center;
    margin: 0 auto 20px;
}

.medisharp-confirmed-icon {
    font-size: 60px;
    display: block;
    text-align: center;
    margin: 0 auto 20px;
    color: #07796a;
}

span.medisharp-countdown {
    display: block;
    max-width: 100px;
    margin: 15px auto 0;
    text-align: center;
    padding: 5px 0;
    background: #dfe5f3;
    border-radius: 5px;
}

.medisharp-sent-phone-sms {
    color: #07796a;
    font-weight: 600;
}
.mtop-30 {
    margin-top: 30px;
}

.mtop-50 {
    margin-top: 50px;
}

.mtop-20 {
    margin-top: 20px;
}

.medisharp-confirm-container-vh {
    min-height: 100vh
}

.g-recaptcha {
    margin-top: 30px;
}
@keyframes pulse-green {
    0% {
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
    }

    70% {
        box-shadow: 0 0 0 13px rgba(51, 217, 178, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
}